import AboutUs from "./AboutUs";

const Landingpage = () => {
  return (
    <>
      <AboutUs />
    </>
  );
};

export default Landingpage;
