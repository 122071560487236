import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState, Fragment } from "react";
import crudApi from "@serie3/common/api/crudApi";
import getSettings from "@serie3/common/config/settings";
import { NavLink } from "react-router-dom";
import { Trans } from "react-i18next";

const LexikonList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const settings = getSettings();

  useEffect(() => {
    const loadData = async () => {
      const response = await crudApi.getAll(settings.scubadictonaryapiApiUrl);
      if (response.status === 200) {
        setBlogPosts(response.data.subpages);
      }
    };
    loadData();
  }, []);

  const createSlug = (apiSlug) => {
    if (apiSlug) {
      return apiSlug.replace("scubadictonaryapi", "lexikon");
    }
  };

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col>
              <div className="section-heading">
                <h2 className="display-20 display-md-18 display-lg-16">
                  <Trans i18nKey="Lexikon.About" />
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
             <b> <Trans i18nKey="Lexikon.Description"> <ul><li></li></ul></Trans> </b>
            </Col>
          </Row>

          <Row>
            {Object.entries(blogPosts).map(([header, subObjects]) => (
              <Fragment key={header}>
                <Row>
                  <Col>
                    <div key={header}>
                      <div className="section-heading">
                        <h2 className="display-20 display-md-18 display-lg-16">
                          {header}
                        </h2>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-5">
                  {Object.entries(subObjects).map(([subHeader, details]) => (
                    <Col key={subHeader} md={4} className="mb-3">
                      <NavLink to={createSlug(details["slug"])}>
                        {subHeader}
                      </NavLink>
                    </Col>
                  ))}
                </Row>
              </Fragment>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default LexikonList;
