import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import getSettings from "@serie3/common/config/settings";
import crudApi from "@serie3/common/api/crudApi";
import parseBlogContent from "@serie3/common/Domain/parseContent";

const LexikonEntry = () => {
  const { "*": splat } = useParams();
  const [blogContent, setBlogContent] = useState(null);
  const settings = getSettings();

  useEffect(() => {
    const getData = async () => {
      const response = await crudApi.getAll(
        settings.scubadictonaryapiApiUrl + "/" + splat
      );
      if (response.ok) {
        let parsedContent = parseBlogContent(response.data.content.colPos0[0].content.bodytext, settings.blogUrl);
        setBlogContent(parsedContent);
      }
      window.scrollTo(0, 0);
    };

    getData();
  }, [splat]);

  const containerBackground = {
    // Combine gradient and image in the background property
    background: `linear-gradient(90deg, rgba(36, 36, 36, 0.75) 0%, rgba(36, 36, 36, 0.00) 100%))`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return blogContent ? (
    <section className="blogs">
      <Container>
        <Row>
          <Col md={9} className="col-lg-9 mb-6 mb-lg-0">
            <div className="pr-lg-1-6 pr-xl-2-5">
              <div className="posts">
                <div>
                </div>
                <div className="content">
                  <div dangerouslySetInnerHTML={{ __html: blogContent }} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  ) : null;
};
export default LexikonEntry;
