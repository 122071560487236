import { NavLink } from "react-router-dom";
import { useState } from "react";
import i18n from "../../i18n";
import { Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import titleImage from "@serie3/diversplace/Assets/img/titleImage2.webp";

const Header = ({ currentLanguage = "de", setCurrentLanguage }) => {
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(i18n.language);
  };

  let transkey = "Landingpage.Header";
  if (pathname.includes("/blog")) {
    transkey = "Blog.Header";
  }
  if (pathname.includes("lexikon")) {
    transkey = "Lexikon.Header";
  }

  console.log(transkey);
  return (
    <>
      <header
        className="header-style1 menu_area-light fixedHeader"
      >
        <div className="navbar-default border-bottom border-color-light-white">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-12">
                <div className="menu_area alt-font">
                  <nav className="navbar navbar-expand-lg navbar-light p-0 current">
                    <div className="navbar-header navbar-header-custom">
                      <NavLink to="/" className="navbar-brand">
                        <img
                          id="logo"
                          src="https://felixtravelblog.de/fileadmin/templates/felix/travelblog/template/images/logoNeu.png"
                          alt="logo"
                        />
                      </NavLink>
                    </div>

                    <div
                      className="navbar-toggler"
                      onClick={() => {
                        setShowMenu(!showMenu);
                      }}
                    />

                    <ul
                      className="navbar-nav ml-auto"
                      id="nav"
                      style={{ display: showMenu ? "block" : "" }}
                    >
                      <li>
                        <NavLink to="/blog"><Trans i18nKey="Navi.Blog" /></NavLink>
                      </li>
                      <li className="active">
                        <NavLink to="/lexikon">
                          <Trans i18nKey="Navi.Lexikon" />
                        </NavLink>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {currentLanguage === "de" ? (
                          <div
                            style={{
                              fontSize: "1.2rem",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-4px",
                            }}
                            onClick={() => {
                              changeLanguage("en");
                            }}
                          >
                            &#x1F1EC;&#x1F1E7;
                          </div>
                        ) : (
                          <div
                            style={{
                              fontSize: "1.2rem",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-4px",
                            }}
                            onClick={() => {
                              changeLanguage("de");
                            }}
                          >
                            &#x1F1E9;&#x1F1EA;
                          </div>
                        )}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section
        className="page-title-section bg-img cover-background top-position1 theme-overlay"
        data-overlay-dark="7"
        style={{ backgroundImage: `url(${titleImage})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>
                <Trans i18nKey={transkey} />
              </h1>
            </div>
            <div className="col-md-12">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="#!"><Trans i18nKey={transkey} /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
