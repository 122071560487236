const parseBlogContent = (content, blogUrl) => {

  let parsedContent = content.replace(
    'src="/fileadmin/',
    'src="' + blogUrl + "/fileadmin/"
  );


  return parsedContent
    .replace(/<table/g, '<div style="width: 100%; overflow-x: auto;"><table')
    .replace(/<\/table>/g, "</table></div>");
};

export default parseBlogContent;