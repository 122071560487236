import "@serie3/common/Assets/css/dropzone.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import useToken from "@serie3/common/security/useToken";
import AuthContext from "@serie3/common/auth/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlogList from "./components/blog/BlogList";
import Blogpost from "./components/blog/Blogpost";
import LexikonList from "./components/lexikon/LexikonList";
import LexikonEntry from "./components/lexikon/LexikonEntry";
import Landingpage from "./components/Frontend/Landingpage";
import Layout from "./components/shared/Layout";
import "./assets/css/plugins.css";
import "./assets/css/styles.css";
import i18n from "./i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function App() {
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const { t } = useTranslation();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="main-wrapper">
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Layout
                  currentLanguage={currentLanguage}
                  setCurrentLanguage={setCurrentLanguage}
                />
              }
            >
              <Route path="/blog" element={<BlogList />} />
              <Route path="/blog/:splat" element={<Blogpost />} />
              <Route path="/lexikon/" element={<LexikonList />} />
              <Route path="/lexikon/*" element={<LexikonEntry />} />
              <Route path="/" element={<Landingpage />} />
              <Route path="/*" element={<Landingpage />} />
            </Route>
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
