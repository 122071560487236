import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import blogclient from "@serie3/common/api/blogClient";
import getSettings from "@serie3/common/config/settings";
import { useLocation } from "react-router-dom";
import parseBlogContent from "@serie3/common/Domain/parseContent";

const Blogpost = () => {
  const { splat } = useParams();
  const [blogContent, setBlogContent] = useState("");
  const [header, setHeader] = useState("");
  const [subHeader, setSubHeader] = useState("");
  const [headerImage, setHeaderImage] = useState(null);
  const [description, setDescription] = useState(null);
  const settings = getSettings();


  useEffect(() => {
    const loadData = async () => {
      const response = await blogclient.get(splat);
      if (response.status === 200) {
        let content = response.data.content.colPos0[0].content.bodytext;
        let publicUrl =
          response?.data?.content?.colPos0?.[0]?.content?.gallery?.rows?.[1]
            ?.columns?.[1]?.publicUrl ?? "https://felixtravelblog.de/fileadmin/templates/felix/travelblog/template/images/hintergrundHeaderTauchblog.jpg";
        let parsedContent = parseBlogContent(content, settings.blogUrl);
        setHeader(response.data.meta.title);
        setBlogContent(parsedContent);
        setHeaderImage(publicUrl);
        setSubHeader(response.data.meta.subtitle);
        setDescription(response.data.meta.description);
        window.scrollTo(0, 0);
      }
    };
    loadData();
  }, [splat]);

  const containerBackground = {
    // Combine gradient and image in the background property
    background: `linear-gradient(90deg, rgba(36, 36, 36, 0.75) 0%, rgba(36, 36, 36, 0.00) 100%), url(${headerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return headerImage ? (
    <section className="blogs">
      <Container>
        <Row>
          <Col md={12} className="col-lg-12 mb-6 mb-lg-0">
            <div className="pr-lg-1-6 pr-xl-2-5">
              <div className="posts">
                <div>
                  <img src={headerImage} style={{ width: "100%" }} />
                </div>
                <div className="content">
                  <h2 className="h3">{header}</h2>
                  <div dangerouslySetInnerHTML={{ __html: blogContent }} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  ) : null;
};
export default Blogpost;
