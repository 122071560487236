import { Col } from "react-bootstrap";
import getSettings from "@serie3/common/config/settings";
import { Link } from "react-router-dom";

const BlogCard = ({ post = null }) => {
  const settings = getSettings();

  if (!post || !post.slug) {
    return null; // Early return if post is undefined or null
  }

  const imageUrl = post.image
    ? settings.blogUrl + post.image
    : "https://felixtravelblog.de/fileadmin/templates/felix/travelblog/template/images/hintergrundHeaderTauchblog.jpg";
  const slug = post.slug.replace("blogapi", "blog");

  return (
    <Col className="col-md-6 col-lg-4 mb-1-9 mb-lg-0">
      <div className="blog-wrapper">
        <Link to={slug} className="postIndexLink">
          <img className="mb-1-6" src={imageUrl} style={{width: "100%", maxHeight: "194px"}}/>
          <h3 className="h5 mb-1-6">{post.title}</h3>
          <p>{post.description}</p>
          <a href="#!" class="font-weight-600">
            Read more<i class="fas fa-arrow-right ml-2 display-31"></i>
          </a>
        </Link>
      </div>
    </Col>
  );
};

export default BlogCard;
