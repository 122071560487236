import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ currentLanguage, setCurrentLanguage }) => {
  return (
    <>
      <Header currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage}/>
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
