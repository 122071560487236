import { Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import blogclient from "@serie3/common/api/blogClient";
import { Trans } from "react-i18next";

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const response = await blogclient.get();
      if (response.status === 200) {
        setBlogPosts([...response.data.subpages]);
      }
    };
    loadData();
  }, []);

  return (
    <section>
      <Container>
        <div class="section-heading">
          <h2><Trans i18nKey="Blog.Header" /></h2>
        </div>
        <Row className="g-4 mb-5">
          {blogPosts.map((post, index) => {
            return <BlogCard post={post} key={index} />;
          })}
        </Row>
      </Container>
    </section>
  );
};

export default BlogList;
