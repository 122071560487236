import { Container } from "react-bootstrap";
import React, { useState } from "react";
import { Tab } from "react-bootstrap";
import { Trans } from "react-i18next";
import blogImage from "@serie3/diversplace/Assets/img/blog.webp";
import networkingImage from "@serie3/diversplace/Assets/img/networkingDiver.webp";
import meImage from "@serie3/diversplace/Assets/img/sunnyboy.webp";

const AboutUs = () => {
  const [activeKey, setActiveKey] = useState("first");
  return (
    <section>
      <Container>
        <div className="section-heading">
          <h2 className="display-20 display-md-18 display-lg-16">
            {" "}
            <Trans i18nKey="Landingpage.AboutHeader" />
          </h2>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="col-lg-6 order-2 order-lg-1">
              <div
                className="horizontaltab tab-style1"
                style={{ display: "block", width: "100%", margin: "0px" }}
              >
                <div className="pr-lg-2-5 pr-xl-6"></div>
              </div>
            </div>

            <div
              className="horizontaltab tab-style1"
              style={{ display: "block", width: "100%", margin: "0px" }}
            >
              <div className="pr-lg-2-5 pr-xl-6">
                <ul className="resp-tabs-list hor_1">
                  <li
                    onClick={() => setActiveKey("first")}
                    className={`resp-tab-item hor_1 ${
                      activeKey === "first" ? "resp-tab-active" : ""
                    }`}
                    aria-controls="hor_1_tab_item-0"
                    role="tab"
                  >
                    <span>
                      <Trans i18nKey="Landingpage.Tabs.About" />
                    </span>
                  </li>
                  <li
                    onClick={() => setActiveKey("second")}
                    className={`resp-tab-item hor_1 ${
                      activeKey === "second" ? "resp-tab-active" : ""
                    }`}
                    aria-controls="hor_1_tab_item-1"
                    role="tab"
                  >
                    <span>
                      <Trans i18nKey="Landingpage.Tabs.Mission" />
                    </span>
                  </li>
                  <li
                    onClick={() => setActiveKey("third")}
                    className={`resp-tab-item hor_1 ${
                      activeKey === "third" ? "resp-tab-active" : ""
                    }`}
                    aria-controls="hor_1_tab_item-2"
                    role="tab"
                  >
                    <span>
                      <Trans i18nKey="Landingpage.Tabs.Goals" />
                    </span>
                  </li>
                </ul>
                <Tab.Container
                  activeKey={activeKey}
                  onSelect={(k) => setActiveKey(k)}
                >
                  <Tab.Content>
                    <Tab.Pane
                      eventKey="first"
                      className="mb-1-6 mb-md-1-9 resp-tab-content hor_1 resp-tab-content-active"
                      aria-labelledby="hor_1_tab_item-0"
                    >
                      <p>
                        <Trans i18nKey="Landingpage.Tabs.AboutDescription" />
                      </p>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="second"
                      className="mb-1-6 mb-md-1-9 resp-tab-content hor_1"
                      aria-labelledby="hor_1_tab_item-1"
                    >
                      <p>
                        <Trans i18nKey="Landingpage.Tabs.MissionDescription" />
                      </p>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="third"
                      className="mb-1-6 mb-md-1-9 resp-tab-content hor_1"
                      aria-labelledby="hor_1_tab_item-2"
                    >
                      <p>
                        <Trans i18nKey="Landingpage.Tabs.GoalsDescription" />
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>

          <div className="col-lg-6 order-1 order-lg-2 mb-1-9 mb-md-2-5 mb-lg-0">
            <Tab.Container
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k)}
            >
              <Tab.Content>
                <Tab.Pane
                  eventKey="first"
                  className="mb-1-6 mb-md-1-9 resp-tab-content hor_1 resp-tab-content-active"
                  aria-labelledby="hor_1_tab_item-0"
                  style={{ border: "none" }}
                >
                  <img src={blogImage} alt="old blog"/>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  className="mb-1-6 mb-md-1-9 resp-tab-content hor_1"
                  aria-labelledby="hor_1_tab_item-1"
                  style={{ border: "none" }}
                >
                  <img src={networkingImage} alt="networking"/>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="third"
                  className="mb-1-6 mb-md-1-9 resp-tab-content hor_1"
                  aria-labelledby="hor_1_tab_item-2"
                  style={{ border: "none" }}
                >
                  <img src={meImage} alt="felix dziekan"/>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutUs;
