import { Trans } from "react-i18next";
import titleImage from "@serie3/diversplace/Assets/img/titleImage.webp";

const Footer = () => {
    return (
        <footer className="pt-7 pt-lg-8 pt-xl-10 bg-img cover-background theme-overlay" data-overlay-dark="9" style={{ backgroundImage: `url(${titleImage})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-lg-4 mb-2-9 mb-lg-0">
                        <div className="pr-lg-2-5 pr-xl-2-9">
                            <h3 className="footer-title h5"><Trans i18nKey="Footer.Contact.Header" /></h3>
                            <ul className="address-list">
                                <li className="pt-0">
                                    <span className="d-inline-block display-30 display-sm-29"><i className="fas fa-map-marker-alt text-secondary"></i></span>
                                    <span className="d-inline-block w-85 display-30 display-sm-29 vertical-align-top pl-2"><Trans i18nKey="Footer.Address" /></span>
                                </li>
                                <li>
                                    <span className="d-inline-block display-30 display-sm-29"><i className="fas fa-phone text-secondary"></i></span>
                                    <span className="d-inline-block w-85 display-30 display-sm-29 vertical-align-top pl-2"><Trans i18nKey="Footer.Phone" /></span>
                                </li>
                                <li className="pb-0">
                                    <span className="d-inline-block display-30 display-sm-29"><i className="far fa-envelope text-secondary"></i></span>
                                    <span className="d-inline-block w-85 display-30 display-sm-29 vertical-align-top pl-2"><Trans i18nKey="Footer.Email" /></span>
                                </li>
                            </ul>
                            <ul className="footer-social-icon">
                                <li>
                                    <a href="#!"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="#!"><i className="fab fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#!"><i className="fab fa-youtube"></i></a>
                                </li>
                                <li className="mr-0">
                                    <a href="https://www.linkedin.com/in/felixdziekan/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mb-2-9 mb-lg-0">
                        <div className="pr-lg-2-5 pr-xl-2-9">
                            <h3 className="footer-title h5"><Trans i18nKey="Footer.Gallery.Header" /></h3>

                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <h3 className="footer-title h5"><Trans i18nKey="Footer.Links.Header" /></h3>
                        <div className="row">
                            <div className="col-12">
                                <ul className="footer-list">
                                    <li><a href="/blog/impressum"><Trans i18nKey="Footer.Imprintlink" /></a></li>
                                    <li><a href="/blog/datenschutz"><Trans i18nKey="Footer.Privacylink" /></a></li>
                                    <li><a href="/"><Trans i18nKey="Footer.Startpage" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bar mt-7 mt-lg-8 mt-xl-10">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center text-white display-30">
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;